<template>
	<b-modal id="muteModal" centered title="Mute/Unmute Alert" @hidden="$emit('hidden')">
		<b-form @submit="save">
			<div class="d-flex justify-content-center">
				<b-form-checkbox v-model="form.muted" value="1" unchecked-value="0" switch size="lg">Muted</b-form-checkbox>
			</div>
			<template v-if="form.muted == '1'">
				<div class="col-12 my-5">
					<b-form-group label="Muted By" label-for="mutedname" :description="form.mutedate">
						<b-form-input type="text" id="mutedname" v-model="form.mutedname" disabled />
					</b-form-group>
				</div>
				<div class="col-12 my5">
					<b-form-group label="Mute Until" label-for="muteuntil">
						<b-form-datepicker id="muteuntil" v-model="form.muteuntil" :disabled="muteforever == '1'" />
					</b-form-group>
					<b-form-checkbox v-model="muteforever" value="1" unchecked-value="0" switch>Forever</b-form-checkbox>
				</div>
				<div class="col-12 my-5">
					<b-form-group label="Mute Reason" label-for="mutereason">
						<b-form-input type="text" id="mutereason" v-model="form.mutereason" />
					</b-form-group>
				</div>
			</template>
		</b-form>
		<template #modal-footer>
			<div class="d-flex justify-content-end footer">
				<b-button type="button" variant="secondary" @click="$bvModal.hide('muteModal')">Cancel</b-button>
				<b-button type="submit" variant="primary" :class="[{ 'spinner spinner-white spinner-right': submitting }, 'ml-3', 'px-10']" @click="save"
					>Save</b-button
				>
			</div>
		</template>
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'NOCAlertsLinecardModal',
	props: ['alert'],
	data() {
		return {
			form: {
				muted: null,
				muteuntil: null,
				mutedate: null,
				mutereason: null,
			},
			muteforever: 0,
			mutedname: null,
			submitting: false,
		};
	},
	computed: {
		...mapGetters(['currentUser']),
	},
	methods: {
		setFormVals(alert) {
			this.form.muted = alert.muted == '1' ? '0' : '1';
			this.form.mutereason = '';
			this.form.mutedname = this.currentUser.username;
			let currentDate = new Date().toISOString().split('T');
			this.form.mutedate = currentDate[0] + ' ' + currentDate[1].substring(0, 8);
			this.muteforever = this.form.muteuntil == '0000-00-00 00:00:00' ? '1' : '0';
		},
		save() {
			this.submitting = true;
			if (this.muteforever === '1') {
				this.form.muteuntil = '0000-00-00 00:00:00';
			}
			this.$http.post(`/nocalertlinecard/${this.alert.id}`, { data: this.form }).then(resp => {
				if (resp.data.data == 'success') {
					this.submitting = false;
					this.$bvModal.hide('muteModal');
					this.$emit('saved', { id: this.alert.id, ...this.form });
				}
			});
		},
	},
	watch: {
		alert(newval) {
			if (newval) {
				this.setFormVals(newval);
			}
		},
		muteforever(newval) {
			this.form.muteuntil = '';
		},
	},
};
</script>

<style scoped></style>
